<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head back="Convention.Volunteers.Roles.Edit" :backParams="$route.meta.backParams" title="Slots" subtitle="Manage the scheduled slots for a volunteer role." :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no slots found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Day" />
				<app-table-column text="From" />
				<app-table-column text="To" />
				<app-table-column align="center" text="Volunteers" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date.start | formatDate('dddd')" />
					<app-table-cell-text :text="item.date.start | formatDate('h:mma')" />
					<app-table-cell-text :text="item.date.end | formatDate('h:mma')" />
					<app-table-cell-text align="center" :text="item.count.filled.toString() + ' / ' + item.capacity.toString()" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '120px 120px auto 120px 24px',
			endpoint: 'convention/volunteers/roles/:role/slots',
			live: 'convention/volunteers/roles/:role/slots'
		}

	}

}

</script>

<style scoped>

</style>